import React, { useEffect, useState } from "react";
import api from "../../api/apiContact";
import { MainPageData } from "../../types/types";

import Connect from "../../components/Connect/Connect";

const Main: React.FC = () => {
	const [pageData, setPageData] = useState<MainPageData | null>(null);

	useEffect(() => {
		const fetchData = async () => {
			try {
				const data = await api.getMainPageData();
				setPageData(data);
			} catch (error) {
				console.log(error);
			}
		};

		fetchData();
	}, []);

	if (!pageData || !pageData.connect) {
		return <div>Loading data...</div>;
	}

	return <Connect data={pageData.connect} openModal={() => {}} />;
};

export default Main;
